exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Palette generated by Material Palette - materialpalette.com/amber/deep-purple */\na {\n  color: gray;\n  text-decoration: none;\n  border-bottom: 1px dotted gray;\n}\n.colored-button__colored-button___3kjzD {\n  background: #7C4DFF;\n  color: white;\n}\n.colored-button__colored-button___3kjzD > button {\n  background: inherit;\n  color: inherit;\n  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3);\n}\n", ""]);

// exports
exports.locals = {
	"colored-button": "colored-button__colored-button___3kjzD",
	"coloredButton": "colored-button__colored-button___3kjzD"
};