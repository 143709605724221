enum Errors {
	NOT_WS,
	NOT_VALID_SERVER,
	NOT_VALID_IP,
	WRONG_PASSWORD,
	UNEXPECTED_CLOSE
}

const ErrorMessages = {
	[Errors.NOT_WS]: "L'adresse IP mène à aucun serveur WebSocket.",
	[Errors.NOT_VALID_SERVER]: "Le serveur réponds mal >:(",
	[Errors.NOT_VALID_IP]: "Ceci n'est pas une adresse IP valide",
	[Errors.WRONG_PASSWORD]: "Mauvais mot de passe",
	[Errors.UNEXPECTED_CLOSE]: "Connexion fermée de manière inattendue"
}

export default Errors

export function getErrorMessage(error: Errors) {
	return ErrorMessages[error]
}
