import Color from "../types/color"

/**
 * Retourne à partir d'une couleur si cette dernière est sombre ou pas
 */
export function isColorDark(color: Color): boolean {
	const yiq = (color.R * 299 + color.G * 587 + color.B * 114) / 1000

	return !(yiq >= 128)
}

/**
 * Retourne une couleur sous format RGB
 * avec getComputedStyle
 * @param color - Une couleur CSS
 * @return {Color}
 */
export function parseColorWithComputedStyle(color: string): Color | null {
	const div = document.createElement("div")
	document.body.appendChild(div)

	div.style.color = color

	const result = getComputedStyle(div).color!
		  .match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i)

	div.parentNode!.removeChild(div)

	if (!result)
		return null

	return { R: parseInt(result[1], 10), G: parseInt(result[2], 10), B: parseInt(result[3], 10) }
}

export function parseColor(color: string) {
	return parseColorWithComputedStyle(color)
}
