import * as React from "react"

import { rippleContainer } from "./ripple.less"

interface IRippleContainerProps {
	/**
	 * Offre un superbe callback pour exporter le component
	 * du rippleContainer en tant que variable
	 */
	refExporter: (rippleContainer: RippleContainer) => any
}

interface IRippleContainerState {
	ripples: React.ReactNode[]
}

/**
 * Le superbe component qui doit contenir tous les ripples
 * @prop {HTMLElement} element - L'élément qui est en charge de contenir tous les ripples !
 * @prop {number} rippleKey - Permet de donner les clés aux éléments React/JSX
 */
export default class RippleContainer extends React.Component<IRippleContainerProps, IRippleContainerState> {
	protected element: HTMLElement | null = null
	protected rippleKey: number = 0
	private unmounted: boolean = false

	constructor(props: Readonly<IRippleContainerProps>) {
		super(props)
		this.props.refExporter(this)

		this.state = { ripples: [] }
	}

	/**
	 * Retourne une clé unique pour les éléments React/JSX
	 */
	public key() {
		return this.rippleKey++
	}

	/**
	 * Append un ripple à lui-même ! et il le supprime 1s plus tard
	 * @param ripple - Un élément JSX représentant un Ripple
	 * @param [timeout=1000] - Le timeout avant que l'élément précédant soit supprimé
	 */
	public appendRipple(ripple: JSX.Element, timeout: number = 1000) {
		setTimeout(() => {
			if (this.unmounted)
				return

			this.setState(previousState => ({
				ripples: previousState.ripples.filter(v => v !== ripple)
			}))
		}, timeout)

		this.setState(previousState => ({
			ripples: [...previousState.ripples, ripple]
		}))
	}

	public render() {
		return <div ref={ node => this.element = node }
			style={ { height: "100%", width: "100%" } } className={rippleContainer} >
			{ this.state.ripples }
		</div>
	}

	public componentWillUnmount() {
		this.unmounted = true
	}
}
