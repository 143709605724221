
/**
 * Removes items of an object from keys
 * @param {Object} object An object
 * @param {any[]} keys Keys to remove
 */
export default function omit(object: object, keys: any[]): any {
	object = Object.assign({}, object)
	keys.forEach(el => delete object[el])
	return object
}
