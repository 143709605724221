exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Palette generated by Material Palette - materialpalette.com/amber/deep-purple */\na {\n  color: gray;\n  text-decoration: none;\n  border-bottom: 1px dotted gray;\n}\n.new-component-modal__container___3YAd3 {\n  overflow-y: auto;\n}\n.new-component-modal__container___3YAd3 .new-component-modal__alimentation___dqR-x {\n  transition: height 0.3s;\n  height: 0px;\n  overflow: hidden;\n}\n.new-component-modal__container___3YAd3 .new-component-modal__alimentation___dqR-x > div {\n  display: flex;\n}\n.new-component-modal__container___3YAd3 .new-component-modal__alimentation___dqR-x > div > div {\n  margin: 0 5px;\n}\n.new-component-modal__subtitle___33Z-J {\n  text-indent: 15px;\n  margin: 20px;\n}\n.new-component-modal__subtitle___33Z-J:before {\n  content: '';\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  display: block;\n  content: '\\2192';\n  text-indent: -8px;\n  position: relative;\n  display: inline;\n  margin: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "new-component-modal__container___3YAd3",
	"alimentation": "new-component-modal__alimentation___dqR-x",
	"subtitle": "new-component-modal__subtitle___33Z-J"
};