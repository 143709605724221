import * as React from "react"

import { widthFixer } from "./width-fixer.less"

interface IWidthFixerProps {
	children: React.ReactNode
}

export default class WidthFixer extends React.Component<IWidthFixerProps> {
	public render() {
		return <div className={ widthFixer }> { this.props.children } </div>
	}
}
