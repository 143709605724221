exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Palette generated by Material Palette - materialpalette.com/amber/deep-purple */\na {\n  color: gray;\n  text-decoration: none;\n  border-bottom: 1px dotted gray;\n}\n.fab-button__fab-button___2EfYs {\n  position: fixed;\n  bottom: 25px;\n  right: 25px;\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  background: #7C4DFF;\n  color: white;\n}\n.fab-button__fab-button___2EfYs > .isvg {\n  display: flex;\n  align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"fab-button": "fab-button__fab-button___2EfYs",
	"fabButton": "fab-button__fab-button___2EfYs"
};