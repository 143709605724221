import * as crypto from "crypto"
import { sha256 } from "js-sha256"

export default class Crypting {
	private userKey = crypto.getDiffieHellman("modp14")

	private aesKey: Uint8Array | null = null

	// procedure for a diffie hellman exchange key
	/**
	 * Generates key and returns public key as a string
	 */
	public getPublicKey() {
		this.userKey.generateKeys()

		return this.userKey.getPublicKey()
	}

	public computeSecret(serverkey: any) {
		serverkey = Buffer.from(serverkey)

		this.aesKey = this.userKey.computeSecret(serverkey)
	}

	public crypt(str: string) {
		if (this.aesKey === null)
			return str

		const key = this.aesKey!.slice(0, 256 / 8), // key length de 256/8 (nb de bytes) avec l'algo aes-256-cbc
			cipher = crypto.createCipheriv("aes-256-cbc", key!, sha256(key).substring(0, 16))

		let crypted = cipher.update(str, "utf8", "hex")
		crypted += cipher.final("hex")
		return crypted
	}

	public decrypt(str: string) {
		if (this.aesKey === null)
			return str

		const key = this.aesKey!.slice(0, 256 / 8),
			decipher = crypto.createDecipheriv("aes-256-cbc", key!, sha256(key).substring(0, 16))

		let decrypted = decipher.update(str, "hex", "utf8")

		decrypted += decipher.final("utf8")
		return decrypted
	}
}
