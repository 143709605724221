import * as React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import AppInstance from "./AppInstance"
import Header from "./components/header/Header"
import isHttps from "./lib/isHttps"
import NotFound from "./pages/404/NotFound"
import AboutPage from "./pages/about-page/AboutPage"
import ComponentsPage from "./pages/components-page/ComponentsPage"
import ConnectingPage from "./pages/connect-page/ConnectingPage"
import ConnectPage from "./pages/connect-page/ConnectPage"
import DebugComponentsPage from "./pages/debug-components/DebugComponentsPage"
import DisconnectPage from "./pages/disconnect-page/DisconnectPage"
import HomePage from "./pages/home-page/HomePage"
import PiHomePage from "./pages/pi-home-page/PiHomePage"
import SwitchToHttpPage from "./pages/switch-to-http/SwitchToHttp"

import { EIcons } from "./res/icons"

import "./css/index.less"

export default class App extends React.Component<{}, {}> {
	public static APP_INSTANCE: AppInstance = AppInstance.init()

	constructor(props: Readonly<{}>) {
		super(props)
	}

	public render(): React.ReactNode {
		if (isHttps())
			return <SwitchToHttpPage />

		return <div>
			{ /* Header */ }
			<Route path="/" render={ props => {
				const {pathname} = props.location

				// partie publique
				if (pathname.indexOf("/app/") !== 0)
					return this.publicHeader()
				else
					// pas de header dans les pages de (dé)connexion
					if (pathname.indexOf("connect") > -1 && pathname.indexOf("disconnect") === -1)
						return null
					else // partie "privée" (c-à-d: nécessite une connexion à un contrôleur)
						if ( App.APP_INSTANCE.connection === null ) {
							// redirige /app/:ip/modules vers /app/:ip/connect/modules pour se reconnecter
							const position = pathname.lastIndexOf("/")
							return <Redirect to={ [pathname.slice(0, position), "/connect", pathname.slice(position)].join("") } />
						}

				return null
			}} />

			<Route path="/app/:ip" render={ props => {
				const ip = props.match.params.ip,
					{pathname} = props.location

				if (pathname.indexOf("connect") > -1
					&& pathname.indexOf("disconnect") === -1)
					return null

				return this.connectedHeader(ip)
			}} />

			{/* Page content */}
			<Switch>
				{/* Basic */}
				<Route exact path="/" component={HomePage} />
				<Route exact path="/se-connecter" component={ConnectPage} />
				<Route exact path="/a-propos" component={AboutPage} />

				{/* Connecting */}
				<Route path="/app/:ip/connect/:path" render={actualLocation => {
					const {params} = actualLocation.match
					return <ConnectingPage ip={ params.ip } path={ params.path } />
				} } />
				<Route path="/app/:ip/connect" render={actualLocation => {
					const {params} = actualLocation.match
					return <ConnectingPage ip={ params.ip } />
				} } />

				{<Route path="/app/:ip/accueil" render={actualLocation => {
					return <PiHomePage ip={actualLocation.match.params.ip} />
				} } /> }

				{/* Quand Nathan se plaint que rien est prêt, faut préparer un petit truc au moins */}
				<Route path="/app/:ip/debug" render={() => <DebugComponentsPage /> } />

				{<Route path="/app/:ip/modules" render={actualLocation => {
					return <ComponentsPage />
				} } /> }

				<Route path="/app/:ip/disconnect" component={DisconnectPage} />

				<Route component={NotFound} />
			</Switch>
		</div>
	}

	private publicHeader() {
		return <Header tabs={
			[{
				name: "Accueil",
				icon: EIcons.HomeIcon,
				to: "/",
				exact: true
			}, {
				name: "À propos",
				icon: EIcons.Help,
				to: "/a-propos"
			},
			{
				name: "Se connecter",
				icon: EIcons.Connect,
				to: "/se-connecter",
				exact: true
			}]
		}></Header>
	}

	private connectedHeader(ip: string) {
		return <Header tabs={
			[{
				name: "Accueil du contrôleur",
				icon: EIcons.HomeIcon,
				to: `/app/${ip}/accueil`,
				exact: true
			}, {
				name: "Modules",
				icon: EIcons.Devices,
				to: `/app/${ip}/modules`,
				exact: true
			}, {
				name: "Se déconnecter",
				icon: EIcons.Cross,
				to: `/app/${ip}/disconnect`,
				exact: true
			}]
		}></Header>
	}
}
