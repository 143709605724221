import isValidIP from "../lib/isValidIP"

export default class IP {
	public static isValidIP = isValidIP

	private ip: string

	constructor(str: string) {
		if (!isValidIP(str))
			throw new Error("Non-valid IP.")

		this.ip = str
	}

	public toString(): string {
		return this.ip
	}
}
