import { ReactNode } from "react"

export default function multipleSameElement(howMany: number, func: (i: number) => ReactNode) {
	/*
		On explique ce caca rapidement:
		- "Array.apply(null, new Array(howMany))" créé un array de length=howMany, non-vide
		- ".map" itère sur tous les éléments de l'array et ajoute ce que retourne func dans l'array qu'il retournera
	*/
	return Array.apply(null, new Array(howMany)).map((v: any, i: number) => func(i))
}
