import * as React from "react"

import { modalBar } from "./modal.less"

export default class ModalBar extends React.Component {
	public render() {
		return <>
			<hr className={ modalBar } />
		</>
	}
}
