exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style__background___1Yczj {\n  width: 300px;\n  height: 300px;\n  border-radius: 50%;\n  background: rgba(0, 0, 0, 0.1);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n}\n.style__background___1Yczj > img {\n  width: 95%;\n}\n", ""]);

// exports
exports.locals = {
	"background": "style__background___1Yczj"
};