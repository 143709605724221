import * as React from "react"
import { NavLink } from "react-router-dom"

import AppInstance from "../../AppInstance"
import TooltipApplier from "../../components/design/appliers/tooltip/TooltipApplier"
import { EIcons, Icon } from "../../res/icons"

const appInstance = AppInstance.init()

export default class OldConnectionsFastConnect extends React.Component {
	public render() {
		const lastConnections = appInstance.lastConnections.get("history")

		if (!(lastConnections instanceof Array) || lastConnections.length === 0)
			return null

		return <div>
			<h2>Dernières connexions.</h2>
			<p>Connectez-vous rapidement ici.</p>

			{ lastConnections.reverse().map((value, index) =>
				<div key={index}>
					<NavLink to={`/app/${ value }/connect`}>{value}</NavLink>
					<TooltipApplier text={"supprimer"}>
						<span onClick={() => {
							appInstance.lastConnections.remove("history", index)
							this.forceUpdate()
						}}><Icon icon={EIcons.Cross} /></span>
					</TooltipApplier>
				</div>
			)}
		</div>

	}
}
