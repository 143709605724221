exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".about-page__about-page___15R6x > h2 {\n  text-indent: 50px;\n}\n.about-page__about-page___15R6x > p {\n  text-indent: 25px;\n}\n.about-page__about-page___15R6x > h3 {\n  text-indent: 5px;\n  text-decoration: underline;\n}\n.about-page__about-page___15R6x > h3 + p {\n  margin-top: 0;\n}\n.about-page__about-page___15R6x > div {\n  position: relative;\n}\n.about-page__about-page___15R6x .about-page__terminalImage___dEh-R {\n  float: right;\n}\n@media all and (max-width: 1100px) {\n  .about-page__about-page___15R6x .about-page__terminalImage___dEh-R {\n    position: absolute;\n    opacity: 0.2;\n    right: 2%;\n  }\n}\n@media all and (max-width: 750px) {\n  .about-page__about-page___15R6x .about-page__terminalImage___dEh-R {\n    display: none;\n  }\n}\n.about-page__about-page___15R6x .about-page__terminalImage___dEh-R img {\n  width: 150%;\n  position: relative;\n  left: 30%;\n}\n.about-page__about-page___15R6x .about-page__substeps___3_x5Q > p {\n  text-indent: 25px;\n}\n.about-page__about-page___15R6x .about-page__substeps___3_x5Q > div {\n  border-left: 2px solid gray;\n  padding-left: 15px;\n}\n.about-page__about-page___15R6x img {\n  margin: auto;\n  display: block;\n  width: 100vw;\n}\n", ""]);

// exports
exports.locals = {
	"about-page": "about-page__about-page___15R6x",
	"aboutPage": "about-page__about-page___15R6x",
	"terminalImage": "about-page__terminalImage___dEh-R",
	"substeps": "about-page__substeps___3_x5Q"
};