import * as React from "react"

import { IOffsetCoordinates } from "../../../../lib/offset"

import * as style from "./tooltip.less"

interface ITooltipContainerProps {
	refExporter: (tooltipContainer: TooltipContainer) => any
}

interface ITooltipContainerState {
	text: string,
	show: boolean
}

export default class TooltipContainer extends React.Component<ITooltipContainerProps, ITooltipContainerState> {
	constructor(props: Readonly<ITooltipContainerProps>) {
		super(props)
		this.props.refExporter(this)

		this.state = { show: false, text: "" }
	}

	public render() {
		if (!this.state.show)
			return null

		return <div className={ style.tooltip }>
			<div>
				<p>{ this.state.text }</p>
			</div>
		</div>
	}

	public showTooltip(text: string) {
		this.setState({ show: true, text })
	}

	public hideTooltip() {
		this.setState({ show: false, text: "" })
	}
}
