import * as React from "react"

import classnames from "classnames"
import { CSSTransition } from "react-transition-group"
import Component from "../../app/Component"
import BaseButton from "../../components/buttons/base-button/BaseButton"
import SwitchCheckbox from "../../components/design/switch-checkbox/SwitchCheckbox"
import ComponentType from "../../enums/ComponentType"
import { EIcons, Icon } from "../../res/icons"

import * as style from "./component-item.less"

interface IComponentItemProps {
	component: Component,
	showDeleteButton: boolean,
	disabled?: boolean

	onSwitch(value: boolean | number): any,
	onDelete(): any
}

// TODO: icons for components

export default class ComponentItem extends React.Component<IComponentItemProps> {
	protected rangeInput: HTMLInputElement | null = null

	constructor(props: Readonly<IComponentItemProps>) {
		super(props)
	}

	public componentDidMount() {
		if (this.rangeInput)
			this.rangeInput.addEventListener("input", e => {
				if (!this.props.disabled)
					this.props.onSwitch(parseInt(this.rangeInput!.value, 10))
			})
	}

	public render() {
		return <CSSTransition in={this.props.showDeleteButton}
			timeout={400}
			classNames={{
				enter: style.deleteButtonAnimationEntering,
				enterDone: classnames(style.deleteButtonAnimationEntering, style.deleteButtonAnimationEnteringDone) }}>
			<div className={ classnames(style.componentItem, {
				[style.buttonSwitch]: this.props.component.type === ComponentType.BUTTON_SWITCH
			}) }>
				<div className={ classnames(style.deleteButton, { [style.disabled]: this.props.disabled }) }
					onClick={ this.props.disabled ? () => undefined : this.props.onDelete }>
					<Icon icon={ EIcons.Cross } />
				</div>

				{ this.renderControl() }
			</div>
		</CSSTransition>
	}

	private renderControl() {
		const comp = this.props.component

		if (comp.type === ComponentType.ONOFF_SWITCH)
			return <SwitchCheckbox
					disabledLabelClick
					disabled={ this.props.disabled }
					checked={ comp.isOn() }
					onChange={ this.props.disabled ? () => undefined : this.props.onSwitch }>
					<Icon icon={EIcons.Bulb} />
					{ comp.name }
				</SwitchCheckbox>
		else if (comp.type === ComponentType.BUTTON_SWITCH)
			return <>
				<Icon icon={EIcons.Bulb} />
				<label>{ comp.name }</label>
				<BaseButton style={ { float: "right" } } disabled={this.props.disabled} onMouseDown={ () => {
					if (this.props.disabled)
						return

					this.props.onSwitch(true)
				}}
				onMouseUp={ () => {
					if (this.props.disabled)
						return

					this.props.onSwitch(false)
				}}>Activer</BaseButton>
			</>
		else return <>
				<Icon icon={EIcons.Bulb} />
				<label>{ comp.name }</label>
				<input type="range" disabled={this.props.disabled}
					defaultValue={comp.state!.toString()}
					min={comp.min} max={comp.max}
					ref={c => this.rangeInput = c } onChange={() => undefined}/>
			</>
	}
}
