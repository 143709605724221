import * as React from "react"

import classnames from "classnames"
import omit from "../../../lib/omit"
import RippleApplier from "../../design/appliers/ripple/RippleApplier"

import * as style from "./base-button.less"

interface IBaseButton {
	children?: React.ReactNode,
	/**
	 * Un ripple clair ou sombre ?
	 */
	lightRipple?: boolean,
	/**
	 * cf. RippleApplier :
	 * Définit si le ripple doit s'afficher seulement au clic-droit ((e as MouseEvent).which === 1)
	 */
	strictRippleClickButton?: boolean
}

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export type BaseButtonProps = ButtonProps & IBaseButton

/**
 * Simple bouton, material-flat, trop cool !
 * @prop {string} [buttonClass=""] - Classes supplémentaires au bouton (utile pour les classes filles)
 * @prop {boolean} [lightRipple=false] - Un ripple clair ou sombre ?
 * @prop {React.CSSProperties} [styles={}] - Des propriétés CSS en plus à rajouter ? (utile pour les classes filles)
 * @prop {boolean} [strictButtonRipple=true] - cf. strictRippleClickButton
 */
export default class BaseButton<T = {}> extends React.Component<BaseButtonProps & T> {
	protected disabledProps: string[] = ["children", "className", "style"]
	protected buttonClass: string = ""
	protected lightRipple: boolean = false
	protected strictButtonRipple: boolean = true
	protected styles: React.CSSProperties = {}

	constructor(props: Readonly<BaseButtonProps & T>) {
		super(props)

		if (props.lightRipple)
			this.lightRipple = true

		if (props.strictRippleClickButton === false)
			this.strictButtonRipple = false

		this.styles = this.props.style ? this.props.style : {}
	}

	public render() {
		return <div style={ this.styles }
					className={ classnames(style.baseButton, this.props.className, this.buttonClass) }>
				<RippleApplier strictButton={this.strictButtonRipple} light={this.lightRipple}>
					<button { ...this._props }>{ this.props.children! }</button>
				</RippleApplier>
			</div>
	}

	/**
	 * Evite de passer tous les props au <button>
	 */
	protected get _props(): ButtonProps {
		return omit(this.props, this.disabledProps)
	}
}
