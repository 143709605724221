import * as React from "react"

import BaseButton from "../../../components/buttons/base-button/BaseButton"
import Modal from "../Modal"
import ModalBar from "../ModalBar"

import { EIcons, Icon } from "../../../res/icons"
import { buttons, icon } from "../modal.less"

interface IErrorModalProps {
	buttonTitle?: string,
	text: React.ReactNode,
}

export default class ErrorModal extends Modal<IErrorModalProps> {

	protected get childrens(): React.ReactNode {
		const { props } = this

		return <>
			<Icon icon={EIcons.Error} className={icon} /> <p>{ props.text }</p>
			<ModalBar />
			<div className={ buttons }>
				<BaseButton onClick={ () => this.props.onClose(this.props.dataKey, null) }>
					{ props.buttonTitle ? props.buttonTitle : "OK" }
				</BaseButton>
			</div>
		</>
	}
}
