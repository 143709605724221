import * as React from "react"

import Component from "../../app/Component"
import AppInstance from "../../AppInstance"
import Loading from "../../components/loading/Loading"
import ComponentItem from "./ComponentItem"

const appInstance = AppInstance.init()

interface IComponentListProps {
	showDeleteButton: boolean,

	refExporter?: (t: ComponentList) => any
}

interface IComponentListState {
	isConnectionNull: boolean
	components: Component[] | null,
	blocked: boolean
}

export default class ComponentList extends React.Component<IComponentListProps, IComponentListState> {
	constructor(props: Readonly<IComponentListProps>) {
		super(props)

		if (this.props.refExporter)
			this.props.refExporter(this)

		this.state = { components: null, isConnectionNull: false, blocked: false }

		if (appInstance.connection === null)
			this.state = { components: null, isConnectionNull: true, blocked: false }
		else
			this.fetch()
	}

	public render() {
		if (this.state.isConnectionNull)
			return <p>Merci de rafraîchir le navigateur. La connexion n'a pas été établie.
			Si ce message s'affiche souvent, il est peut-être incompatible :/</p>

		if (this.state.components === null)
			return <Loading />

		if (this.state.components.length === 0)
			return <p>Aucun appareil de configuré. Cliquez sur le bouton '+' pour en ajouter.</p>
		else
			return <>
				{this.state.components.map((value, index) => {
					return <ComponentItem disabled={this.state.blocked} key={index}
						showDeleteButton={ this.props.showDeleteButton } component={value}
						onDelete={async () => {
							this.setState({ blocked: true })
							await appInstance.connection!.removeComponent(index)
							this.fetch()
						}}
						onSwitch={(switchValue: boolean | number) => {
							if (typeof switchValue === "boolean")
								switchValue = switchValue ? 255 : 0

							appInstance.connection!.setPinValue(value.pinId, switchValue, index)
						}} />
				})}
			</>
	}

	public fetch() {
		appInstance.connection!.getComponents().then(components => {
			this.setState({ components, blocked: false })
		})
	}
}
