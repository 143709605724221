exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Palette generated by Material Palette - materialpalette.com/amber/deep-purple */\na {\n  color: gray;\n  text-decoration: none;\n  border-bottom: 1px dotted gray;\n}\n.description-item__item___VTBle {\n  display: flex;\n  justify-content: space-evenly;\n}\n@media all and (max-width: 750px) {\n  .description-item__item___VTBle > :first-child {\n    display: none;\n  }\n}\n.description-item__item___VTBle > .description-item__content___jNf0c {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-left: 20px;\n}\n.description-item__item___VTBle > .description-item__content___jNf0c > h2::before {\n  content: '';\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  display: block;\n  content: '\\2192';\n  text-indent: -8px;\n  position: relative;\n  display: inline;\n  margin: 10px;\n}\n.description-item__item___VTBle > .description-item__content___jNf0c > p {\n  text-indent: 15px;\n}\n", ""]);

// exports
exports.locals = {
	"item": "description-item__item___VTBle",
	"content": "description-item__content___jNf0c"
};