import * as React from "react"

import classnames from "classnames"

import * as style from "./base-page.less"

interface IBasePageComponentProps {
	className?: string
}

export default class BasePageComponent extends React.Component<IBasePageComponentProps> {
	public render() {
		return <div
			className={ classnames(style.defaultPage, this.props.className) }
			style={ { flexBasis: "100vw" }}>
			{ this.props.children }
		</div>
	}
}
