exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".base-page__default-page___3NM29 {\n  overflow: auto;\n}\n", ""]);

// exports
exports.locals = {
	"default-page": "base-page__default-page___3NM29",
	"defaultPage": "base-page__default-page___3NM29"
};