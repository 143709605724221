import * as React from "react"

import { Transition } from "react-transition-group"
import Component from "../../../app/Component"
import ComponentType from "../../../enums/ComponentType"
import BaseButton from "../../buttons/base-button/BaseButton"
import SwitchCheckbox from "../../design/switch-checkbox/SwitchCheckbox"
import Input from "../../input/Input"
import Modal, { IModalBaseProps } from "../Modal"
import ModalBar from "../ModalBar"

import { buttons, title } from "../modal.less"
import * as style from "./new-component-modal.less"

interface INewComponentModalProps {
	onClose(modalId: number, data: Component | null): void
}

interface INewComponentModalState {
	componentType: ComponentType,
	mobile: boolean
}

export default class NewComponentModal
	extends Modal<INewComponentModalProps & IModalBaseProps, INewComponentModalState> {
	private componentNameInput: Input | null = null
	private pinIdInput: Input | null = null
	private minValueInput: Input | null = null
	private maxValueInput: Input | null = null

	constructor(props: Readonly<INewComponentModalProps & IModalBaseProps>) {
		super(props)
		this.state = { mobile: false, componentType: ComponentType.ONOFF_SWITCH }
	}

	protected get childrens(): React.ReactNode {
		return <>
			<h1 className={title}>Ajouter un module</h1>

			<ModalBar/>

			<div className={style.container}>
				<Input placeholder="Nom du module"
					checker={ content => content.length > 0 && content.length <= 50 ? null
						: "Merci d'entrer un nom compris entre 1 et 50 caractères." }
					noCheckAtMount
					autoComplete={"off"}
					ref={ component => this.componentNameInput = component } />

				<h3 className={style.subtitle}>Alimentation</h3>

				<SwitchCheckbox checked={true} onChange={checked => {
					this.setState({ componentType: checked ? ComponentType.ONOFF_SWITCH
					: ComponentType.PWM_SWITCH })
				}}>Module On/Off</SwitchCheckbox>

				<Transition in={this.state.componentType === ComponentType.PWM_SWITCH}
					timeout={100}
					onEnter={(node: HTMLElement) => {
						const wrapper = node.querySelector("div")
						node.style.height = (wrapper!.clientHeight + 35) + "px"
					}}
					onExit={(node: HTMLElement) => {
						node.style.height = "0px"
					}}>
					<div className={style.alimentation}>
						<div>
							<Input type="number"
								autoComplete={"off"}
								pattern={"\\d{1,3}"}
								placeholder="Min alimentation"
								defaultValue={"0"} min={0} max={254}
								checker={ value => {
									if (!/^\d+$/.test(value))
										return "Entrez un nombre hé ho !"

									if (this.maxValueInput !== null &&
										parseInt(this.maxValueInput!.value, 10) < parseInt(value, 10))
										return "Valeur maximale plus petite que la minimal ?!"

									return null
								} }
								ref={ component => this.minValueInput = component } />
							<Input type="number"
								autoComplete={"off"}
								pattern={"\\d{1,3}"}
								placeholder="Max alimentation"
								defaultValue={"255"} min={0} max={255}
								checker={ value => {
									if (!/^\d+$/.test(value))
										return "Entrez un nombre hé ho !"

									if (parseInt(this.minValueInput!.value, 10) > parseInt(value, 10))
										return "Valeur minimale plus grande que la maximale ?!"

									if (parseInt(value, 10) > 255)
										return "La valeur doit être comprise entre 0 et 255"

									return null
								} }
								ref={ component => this.maxValueInput = component } />
						</div>
					</div>
				</Transition>

				<Transition in={[ComponentType.BUTTON_SWITCH, ComponentType.ONOFF_SWITCH].indexOf(this.state.componentType) > -1}
					timeout={100}
					appear={true}
					onEnter={(node: HTMLElement) => {
						const wrapper = node.querySelector("div")
						node.style.height = (wrapper!.clientHeight + 35) + "px"
					}}
					onExit={(node: HTMLElement) => {
						node.style.height = "0px"
					}}>
					<div className={style.alimentation}
						style={ { display: "flex" } }>
						<SwitchCheckbox checked={this.state.componentType === ComponentType.BUTTON_SWITCH} onChange={checked => {
							this.setState({ componentType: checked ? ComponentType.BUTTON_SWITCH : ComponentType.ONOFF_SWITCH })
						}}>Bouton pressoir</SwitchCheckbox>
					</div>
				</Transition>

				<Input type="number"
					autoComplete={"off"}
					noCheckAtMount
					pattern={"\\d{1,2}"}
					placeholder="Numéro du pin"
					checker={ value => /^\d+$/.test(value) ? null : "Entrez un nombre hé ho !"}
					ref={ component => this.pinIdInput = component } />

				<br/>

				<p>Pour plus d'informations à propos des pins, merci de vérifier <a target={"_blank"}
					href={"https://fr.pinout.xyz/"}
					style={ { borderBottom: "1px dotted gray"}}>pinout.xyz</a>.</p>
				<p>Vous devez donner le « BCM pin » correspondant à votre broche.</p>

			</div>
			<ModalBar/>

			<div className={buttons} >
				<BaseButton onClick={ () => this.onClose(null) }>Annuler</BaseButton>
				<BaseButton onClick={ () => this.add() }>Ajouter</BaseButton>
			</div>
			</>
	}

	private add() {
		const check = [this.componentNameInput,
					 this.pinIdInput]

		if (this.state.componentType === ComponentType.PWM_SWITCH)
			check.push(this.minValueInput, this.maxValueInput)

		if (check.filter(el => !el!.isValid()).length > 0) // check if there are inputs with errors
			return

		let defaultState: number = 0

		if (ComponentType.PWM_SWITCH)
			defaultState = parseInt(this.minValueInput!.value, 10)

		this.onClose(new Component({
			type: this.state.componentType,
			pinId: parseInt(this.pinIdInput!.value, 10),
			name: this.componentNameInput!.value,
			max: parseInt(this.maxValueInput!.value, 10),
			min: parseInt(this.minValueInput!.value, 10),
			state: defaultState
		}))
	}
}
