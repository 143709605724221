import * as React from "react"
import offset from "../../../../lib/offset"
import Ripple from "./Ripple"
import RippleContainer from "./RippleContainer"

interface IRippleApplierProps {
	/**
	 * Définit si le ripple sera clair ou sombre.
	 */
	light?: boolean,
	/**
	 * Définit si le ripple doit s'afficher seulement au clic-droit ((e as MouseEvent).which === 1)
	 */
	strictButton?: boolean,
	children: React.ReactElement<any>,

	childRef?(child: HTMLElement): void
}

/**
 * Ripple Applier.
 * Un component qui traitera son children de manière à ce qu'il
 * soit réactif au clics via un ripple.
 * @prop {HTMLElement} element - L'élement sur lequel le Ripple sera effectué
 * @prop {boolean} light - Ripple clair ou sombre
 */
export default class RippleApplier extends React.Component<IRippleApplierProps> {
	private element: HTMLElement | null = null
	private light: boolean
	private rippleContainer: RippleContainer | null = null

	constructor(props: Readonly<IRippleApplierProps>) {
		super(props)

		this.light = props.light ? true : false
	}

	public componentDidMount() {

		// ajout de l'event mousedown.
		this.element!.addEventListener("mousedown", e => {
			if (this.props.strictButton && e.which !== 1)
				return

			this.applyRipple(e)
		}, true)
		// ajout de l'event touchstart.
		this.element!.addEventListener("touchstart", e => {
			const t = e.touches[0]

			this.applyRipple({
				x: t.pageX,
				y: t.pageY
			})
		}, true)
	}

	public render() {
		return React.Children.map(this.props.children, child =>
				React.cloneElement(child as React.ReactElement<any>,
					{ ref: (node: HTMLElement) => {
						this.element = node

						if (this.props.childRef)
							this.props.childRef(node)
					} }, (child as React.ReactElement<any>).props.children,
					<RippleContainer refExporter={ rC => this.rippleContainer = rC } />)
			)
	}

	/**
	 * Applique un ripple sur un children depuis un MouseEvent
	 * @param {{x: number, y: number}} e - Event source du ripple
	 */
	protected applyRipple(e: {x: number, y: number}) {
		const position = offset(this.element!),
			x = e.x - position!.x,
			y = e.y - position!.y

		this.rippleContainer!.appendRipple(<Ripple pos={ {x, y} } light={this.light}
			key={ this.rippleContainer!.key() } />)
	}
}
