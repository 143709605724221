exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Palette generated by Material Palette - materialpalette.com/amber/deep-purple */\na {\n  color: gray;\n  text-decoration: none;\n  border-bottom: 1px dotted gray;\n}\n.top-page-design__top-page-design___1-yvS {\n  position: relative;\n  width: 100%;\n  height: 352px;\n  background: #e49000;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n  transition: height 0.4s;\n}\n.top-page-design__top-page-design___1-yvS > h2 {\n  margin: 0;\n}\n", ""]);

// exports
exports.locals = {
	"top-page-design": "top-page-design__top-page-design___1-yvS",
	"topPageDesign": "top-page-design__top-page-design___1-yvS"
};