export interface IOffsetCoordinates {
	x: number,
	y: number,
	width: number,
	height: number
}

export default function offset(element: HTMLElement): IOffsetCoordinates {
	const rect       = element.getBoundingClientRect(),
		  scrollLeft = window.pageXOffset || document.documentElement!.scrollLeft,
		  scrollTop  = window.pageYOffset || document.documentElement!.scrollTop

	return { x: rect.left + scrollLeft, y: rect.top + scrollTop,
			 width: rect.width,         height: rect.height     }
}
