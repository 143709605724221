import * as React from "react"

import offset from "../../../../lib/offset"
import TooltipContainer from "./TooltipContainer"

interface ITooltipApplierProps {
	text: string,

	children: React.ReactElement<any>,

	childRef?(child: HTMLElement): void
}

export default class TooltipApplier extends React.Component<ITooltipApplierProps> {
	private element: HTMLElement | null = null
	private tooltipContainer: TooltipContainer | null = null

	constructor(props: Readonly<ITooltipApplierProps>) {
		super(props)
	}

	public componentDidMount() {
		this.element!.addEventListener("mouseenter", e => {
			this.showTooltip()
		})

		this.element!.addEventListener("mouseleave", e => {
			this.hideTooltip()
		})
	}

	public render() {
		return React.Children.map(this.props.children, child =>
			React.cloneElement(child as React.ReactElement<any>,
				{ ref: (node: HTMLElement) => {
					this.element = node

					if (this.props.childRef)
						this.props.childRef(node)
				}, style: {
					display: "inline-block"
				}}, (child as React.ReactElement<any>).props.children,
				<TooltipContainer refExporter={ tC => this.tooltipContainer = tC } />)
		)
	}

	private showTooltip() {
		this.tooltipContainer!.showTooltip(this.props.text)
	}

	private hideTooltip() {
		this.tooltipContainer!.hideTooltip()
	}
}
