import * as React from "react"

import ColoredButton from "../../../components/buttons/colored-button/ColoredButton"
import CenterText from "../../../components/util/center-text/CenterText"
import Errors, { getErrorMessage } from "../../../enums/Errors"

interface IErrorConnectingSubpageProps {
	error: Errors
	onBack(): void,
	onRetry(): void,
}

export default class ErrorConnectingSubpage extends React.Component<IErrorConnectingSubpageProps> {
	public render() {
		return <CenterText>
			<p>Une erreur est survenue lors de la connexion au contrôleur :
				<br />
				{ getErrorMessage(this.props.error) }
				<br />
				(errid: { this.props.error }, errname: { Errors[this.props.error!] })
			</p>

			<div>
				<ColoredButton onClick={ () => {
					this.props.onBack()
				}}>Revenir à l'accueil.</ColoredButton>

				<ColoredButton onClick={ () => {
					this.props.onRetry()
				}}>Réessayer</ColoredButton>
			</div>
		</CenterText>
	}
}
