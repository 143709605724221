import * as React from "react"

import classnames from "classnames"
import * as style from "./top-page-design.less"

export interface ITopPageDesignProps {
	height?: number | string,
	topText?: string,
	bottomText?: string,
	children?: React.ReactNode,
	className?: string
}

export default class TopPageDesign extends React.Component<ITopPageDesignProps, {}> {
	public height: number | string
	public topText: string | null
	public bottomText: string
	protected className: string
	protected div: HTMLDivElement | null = null

	constructor(props: Readonly<ITopPageDesignProps>) {
		super(props)

		this.height = props.height ? props.height : 352
		this.topText = props.topText ? props.topText : null
		this.bottomText = props.bottomText ? props.bottomText : ""
		this.className = props.className ? props.className : ""
	}

	public render() {
		return <div ref={ c => this.div = c }
					style={ { height: typeof this.height === "number" ? this.height + "px" : this.height } }
					className={ classnames(this.className, style.topPageDesign) }>
			{ this._renderInside() }
		</div>
	}

	private _renderInside() {
		if (this.topText && this.bottomText)
			return <>
				<h2>{ this.topText }</h2>
				<p>{ this.bottomText }</p>
				</>
		else if (this.props.children)
			return this.props.children
		return
	}
}
