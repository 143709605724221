import * as React from "react"

import omit from "../../../lib/omit"

import * as style from "./style.less"

type IImageWithRoundedBackgroundProps =
	React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

export default class ImageWithRoundedBackground extends React.Component<IImageWithRoundedBackgroundProps> {
	constructor(props: Readonly<IImageWithRoundedBackgroundProps>) {
		super(props)
	}

	public render() {
		return <div className={ this.props.className }>
			<div className={ style.background }>
				<img {...omit(this.props, ["className"])} />
			</div>
		</div>
	}
}
