import * as React from "react"

import AppStorage from "./app/AppStorage"
import Connection from "./app/Connection"
import ErrorModal from "./components/modals/error-modal/Error"
import ConnectedPageBase from "./pages/connected-page-base/ConnectedPageBase"
import IP from "./types/ip"

export default class AppInstance {
	public static init(): AppInstance {
		if (!this.instance)
			this.instance = new AppInstance()

		this.localStorage = window.localStorage

		return this.instance
	}

	private static instance: AppInstance
	private static localStorage: Storage | null = null

	public connection: Connection | null = null
	public lastConnections: AppStorage = new AppStorage(localStorage, "lastconnections")
	public lastConnectionReviveData: AppStorage = new AppStorage(localStorage, "lc.revive")

	public connect(ip: IP): Connection {
		this.connection = new Connection(ip)

		this.connection.on("cancelled", () => {
			this.connection = null
		})

		this.connection.connect()

		return this.connection
	}

	public checkConnection(page: ConnectedPageBase) {
		const openErrorModal = () => page.openModal(ErrorModal, {
			text: "La connexion a été fermée sans aucune raison, merci de rafraîchir le navigateur et de vérifier le Raspberry.",
			buttonTitle: "Rafraîchir.",
			key: page.modalKeyId(),
			onClose: () => location.reload()
		}),
			listener = () => {
				openErrorModal()
			}

		if (this.connection === null)
			openErrorModal()
		else
			this.connection.on("cancelled", listener)

		return listener
	}

	public removeListener(listener: any) {
		if (this.connection)
			this.connection.on("cancelled", listener)
	}
}
