exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fullscreen-loading__fullscreen-loading-background___3QuFD {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background: rgba(0, 0, 0, 0.2);\n  z-index: 99999;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"fullscreen-loading-background": "fullscreen-loading__fullscreen-loading-background___3QuFD",
	"fullscreenLoadingBackground": "fullscreen-loading__fullscreen-loading-background___3QuFD"
};