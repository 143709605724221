export default class WebsocketMessage {
	constructor(private type: string, private object: any = {}) { }

	public serialize(messageId: number) {
		return Object.assign(this.object, {
			type: this.type,
			id: messageId
		})
	}
}
