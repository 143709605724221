import * as React from "react"

import { EIcons as Icons, Icon } from "../../../res/icons"
import RippleApplier from "../../design/appliers/ripple/RippleApplier"
import IInputChild from "../../input/InputChild"
import BaseButton from "../base-button/BaseButton"

import * as style from "./arrowbutton.less"

/**
 * Bouton flèche !
 */
export default class ArrowButton extends BaseButton<IInputChild> {
	protected disabledProps: string[] = ["ref", "children", "className", "parentInput"]
	private btn: HTMLButtonElement | null = null

	public render() {
		return <RippleApplier childRef={ c => this.btn = c as HTMLButtonElement }>
			<button {...this._props} className={ style.arrowButtonContainer }>
				<Icon icon={ Icons.RightArrow } />
			</button>
		</RippleApplier>
	}

	public componentDidMount() {
		if (this.props.parentInput)
			this.btn!.addEventListener("click", () => this.props.parentInput!.enter())
	}
}
