exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".width-fixer__width-fixer___2zz7P {\n  width: 75%;\n  margin: auto;\n}\n@media all and (max-width: 1200px) {\n  .width-fixer__width-fixer___2zz7P {\n    width: 90%;\n  }\n}\n@media all and (max-width: 500px) {\n  .width-fixer__width-fixer___2zz7P {\n    width: 98%;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"width-fixer": "width-fixer__width-fixer___2zz7P",
	"widthFixer": "width-fixer__width-fixer___2zz7P"
};