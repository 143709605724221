import * as React from "react"

interface IConnectedPageState {
	modals: React.ReactNode[]
}

export default class ConnectedPageBase<Props = {}, State = {}>
	extends React.Component<Props, IConnectedPageState & State> {

	public mount: boolean = false

	constructor(props: Readonly<Props>) {
		super(props)

		this.state = {...Object.assign(this.state ? this.state : {}), modals: [] }
	}

	public componentDidMount() {
		this.mount = true
	}

	public componentWillUnmount() {
		this.mount = false

		this.state.modals.forEach((el, key) => {
			this.clearModalId(key)
		})
	}

	public render() {
		return <>
			{ this.state.modals.map(el => el) }
			{ this.renderPage() }
		</>
	}

	public openModal(modal: any, props: any, ...childrens: any) {
		const modals = this.state.modals,
			key = this.modalKeyId()

		props.key = props.dataKey = key

		modals[key] = React.createElement(modal, props, childrens)

		if (!this.mount)
			this.state = { ...Object.assign(this.state), modals }
		else
			this.setState({ ...Object.assign(this.state), modals })

		return key
	}

	public clearModalId(modalKey: number) {
		const modals = Array.from(this.state.modals)

		if (modals[modalKey])
			delete modals[modalKey]

		if (!this.mount)
			this.state = { ...Object.assign(this.state), modals }
		else
			this.setState({ ...Object.assign(this.state), modals })
	}

	public modalKeyId() {
		if (this.state.modals === null)
			return 0
		return this.state.modals.length
	}

	protected renderPage(): React.ReactNode {
		return <></>
	}
}
