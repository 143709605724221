import { isColorDark, parseColor } from "../../../lib/color"
import FlatButton, { BaseButtonProps } from "../base-button/BaseButton"
import { coloredButton } from "./colored-button.less"

interface IColoredButtonProps {
	/**
	 * Un petit background personnalisé ?
	 * Doit être une couleur valide CSS.
	 */
	background?: string,
	/**
	 * Une couleur qui va avec le background ?
	 * Doit être une couleur valide CSS.
	 */
	color?: string
}

export default class ColoredButton extends FlatButton<IColoredButtonProps> {
	constructor(props: Readonly<BaseButtonProps & IColoredButtonProps>) {
		super(props)

		this.buttonClass = coloredButton
		this.disabledProps.push("background")

		if (props.background)
			this.styles.background = props.background

		if (props.color) // si une couleur est déjà définie
			this.styles.color = props.color // on la choisit
		else
			// si non
			if (props.background) // si il y a un background déjà existant
				this.styles.color = this._determineColorFromBackground(props.background)
			else
				this.styles.color = "white"

		// maintenant que this.styles.color est obligatoirement défini, on utilise _isColorDark
		// pour déterminer si le ripple doit être clair ou pas
		if (!props.lightRipple) // aucune valeur donnée
			this.lightRipple = !isColorDark(parseColor(this.styles.color)!)
	}

	/**
	 * Détermine la couleur du texte nécessaire (foreground/color; white/black)
	 * pour que ce soit visible correctement sur un fond de la couleur donnée.
	 * @param background
	 */
	private _determineColorFromBackground(background: string) {
		const color = parseColor(background)!

		return isColorDark(color) ? "white" : "black"
	}
}
