import ComponentType from "../enums/ComponentType"

export interface IComponentSerializationFormat {
	type: ComponentType,
	pinId: number,
	name: string,
	state: number | null,

	min?: number,
	max?: number
}

export default class Component {
	public static deserialize(object: any[] | any): Component | Component[] {
		if (object instanceof Array)
			return object.map(value => Component.deserialize(value)) as Component[]

		return new Component(object as IComponentSerializationFormat)
	}

	public type: ComponentType
	public pinId: number
	public name: string
	public state: number | null = null

	public min: number | undefined
	public max: number | undefined

	constructor(object: IComponentSerializationFormat) {
		this.type = object.type
		this.pinId = object.pinId
		this.name = object.name
		this.state = object.state

		if (this.isPWM()) {
			this.min = object.min ? object.min : 0
			this.max = object.max ? object.max : 255
		}
	}

	public isOn(): boolean {
		if (this.isPWM())
			return this.state! - this.min! >= this.max! / 2

		return this.state === 255
	}

	private isPWM(): boolean {
		return this.type === ComponentType.PWM_SWITCH
	}

}
