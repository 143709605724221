exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tooltip__tooltip___16gss {\n  position: relative;\n  z-index: 9999999;\n}\n.tooltip__tooltip___16gss > div {\n  position: absolute;\n  border-radius: 2px;\n  background: rgba(0, 0, 0, 0.8);\n  top: -50px;\n  z-index: 9999999;\n  -webkit-transform: translateX(-33%);\n          transform: translateX(-33%);\n}\n.tooltip__tooltip___16gss > div > p {\n  font-size: 0.9em;\n  margin: 5px;\n  color: white;\n}\n", ""]);

// exports
exports.locals = {
	"tooltip": "tooltip__tooltip___16gss"
};