import * as React from "react"

import ImageWithRoundedBackground from "../../../components/design/img-with-rounded-bckg/ImageWithRoundedBackground"

import * as style from "./description-item.less"

interface IDescriptionItemProps {
	imgSrc: any,
	children: React.ReactNode
}

export default class DescriptionItem extends React.Component<IDescriptionItemProps> {
	public render() {
		return <div className={ style.item }>
			<ImageWithRoundedBackground src={ this.props.imgSrc } />
			<div className={ style.content }>{ this.props.children }</div>
		</div>
	}
}
