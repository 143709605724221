import * as React from "react"
import * as ReactDOM from "react-dom"

import classnames from "classnames"
import * as style from "./ripple.less"

interface IRippleProps {
	light?: boolean,
	pos: {x: number, y: number},
}

export default class Ripple extends React.Component<IRippleProps> {
	protected light: boolean = false

	constructor(props: Readonly<IRippleProps>) {
		super(props)

		if (props.light)
			this.light = true
	}

	public render() {
		return <div
			className={ classnames(style.ripple, { [style.lightRipple]: this.light },
				{ [style.blackRipple]: !this.light }) }
			style={ { top: `${this.props.pos.y}px`, left: `${this.props.pos.x}px` } }/>
	}
}
