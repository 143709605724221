exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".connecting-page__password-page___cDvSi {\n  max-width: 512px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin: auto;\n}\n.connecting-page__password-page___cDvSi > div:first-child {\n  height: auto;\n}\n", ""]);

// exports
exports.locals = {
	"password-page": "connecting-page__password-page___cDvSi",
	"passwordPage": "connecting-page__password-page___cDvSi"
};