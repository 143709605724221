import App from "./App"

import * as OfflinePluginRuntime from "offline-plugin/runtime"
import * as React from "react"
import * as ReactDOM from "react-dom"
import { HashRouter } from "react-router-dom"

OfflinePluginRuntime.install()

ReactDOM.render(
	<HashRouter>
		<App />
	</HashRouter>, document.getElementById("app"))
