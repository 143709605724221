
export default class AppStorage {
	constructor(private localStorage: Storage, private storageName: string) {

	}

	public set(key: string, value: any) {
		return this.localStorage.setItem( this.getKey(key), JSON.stringify(value) )
	}

	public get(key: string) {
		return JSON.parse(this.localStorage.getItem(this.getKey(key))!)
	}

	public add(key: string, value: any) {
		let arr = this.get(key)

		if (arr instanceof Array)
			arr.push(value)
		else
			arr = [value]

		return this.set(key, arr)
	}

	public remove(key: string, index: number) {
		const arr = this.get(key)

		if (arr instanceof Array) {
			arr.splice(index, 1)
			this.set(key, arr)
		}
	}

	private getKey(key: string) {
		return this.storageName + "." + key
	}
}
