import * as React from "react"

import classnames from "classnames"
import { indentedText } from "./indentedtext.less"

interface ICenterTextProps {
	className?: string,
	children: React.ReactNode
}

export default class IndentedText extends React.Component<ICenterTextProps> {
	public render() {
		return <div className={ classnames(this.props.className, indentedText) }>
			{ this.props.children }
		</div>
	}
}
