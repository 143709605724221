exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".centertext__center-text___VLUUY {\n  position: relative;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  text-align: center;\n}\n.centertext__center-text___VLUUY,\n.centertext__center-text___VLUUY p {\n  margin: 0;\n}\n", ""]);

// exports
exports.locals = {
	"center-text": "centertext__center-text___VLUUY",
	"centerText": "centertext__center-text___VLUUY"
};