import * as React from "react"
import { Redirect } from "react-router"

import AppInstance from "../../AppInstance"
import Loading, { LoadingText } from "../../components/loading/Loading"
import BasePageComponent from "../base-page-component/BasePageComponent"

const appInstance = AppInstance.init()

interface IDisconnectPageState {
	isDisconnected: boolean
}

export default class DisconnectPage extends React.Component<{}, IDisconnectPageState> {
	constructor(props: Readonly<{}>) {
		super(props)

		if (appInstance.connection === null)
			this.state = { isDisconnected: true }
		else {
			this.state = { isDisconnected: false };

			(async () => {
				await appInstance.connection!.disconnect()
				this.setState({ isDisconnected: true })
			})()
		}
	}

	public render() {
		if (this.state.isDisconnected)
			return <Redirect to={"/"} />

		return <BasePageComponent>
				<Loading>
					<LoadingText>
						Déconnexion...
					</LoadingText>
				</Loading>
		</BasePageComponent>
	}
}
