import * as React from "react"

import CenterText from "../../components/util/center-text/CenterText"

export default class SwitchToHttpPage extends React.Component {
	public render() {
		return <>
			<CenterText>

				<p>Dû à des restrictions techniques, il est impossible de consulter le site en HTTPS. Merci de passer en HTTP.</p>
				<p>Si cette page se re-affiche malgré le passage en HTTP, merci de vider votre cache.</p>

				<p><a href="#" onClick={ () => location.protocol = "http:" }>Passer en HTTP.</a></p>
			</CenterText>
		</>
	}
}
