import * as React from "react"
import BasePageComponent from "../base-page-component/BasePageComponent"

import Component from "../../app/Component"
import AppInstance from "../../AppInstance"
import FabButton from "../../components/design/fab-button/FabButton"
import NewComponentModal from "../../components/modals/new-component-modal/NewComponentModal"
import FullscreenLoading from "../../components/util/fullscreen-loading/FullscreenLoading"
import IndentedText from "../../components/util/indented-title/IndentedTitle"
import { EIcons, Icon } from "../../res/icons"
import ConnectedPageBase from "../connected-page-base/ConnectedPageBase"
import ComponentList from "./ComponentList"

import * as style from "./components-page.less"

const APP_INSTANCE = AppInstance.init()

interface IComponentsPageState {
	fullscreenLoading: React.ReactNode,
	isDeleting: boolean
}

export default class ComponentsPage extends ConnectedPageBase<{}, IComponentsPageState> {
	private componentList: ComponentList | null = null
	private connectionListener: any = null

	constructor(props: Readonly<{}>) {
		super(props)

		this.connectionListener = APP_INSTANCE.checkConnection(this)

		this.state = { ...this.state, isDeleting: false }
	}

	public componentWillUnmount() {
		super.componentWillUnmount()
		APP_INSTANCE.removeListener(this.connectionListener)
	}

	protected renderPage() {
		return <BasePageComponent>
			{ this.state.fullscreenLoading }

			<IndentedText><h2 className={ style.title }>Vos appareils.</h2></IndentedText>

			<div className={ style.deleteButton }
				onClick={ () => {
					this.setState({ isDeleting: !this.state.isDeleting })
				}}>
				<Icon icon={EIcons.Cross} />
			</div>

			<ComponentList showDeleteButton={ this.state.isDeleting }
				refExporter={ r => this.componentList = r } />

			<FabButton onClick={ () => this.openNewComponentModal() }>
				<Icon icon={ EIcons.Add } />
			</FabButton>
		</BasePageComponent>
	}

	private openNewComponentModal() {
		this.setState({ isDeleting: false })
		this.openModal(NewComponentModal, {
			key: this.modalKeyId(),
			onClose: (modalId: number, data: Component | null) => {
				if (data === null)
					return this.clearModalId(modalId)

				this.setState({ fullscreenLoading:
					<FullscreenLoading
						promiseInstance={ APP_INSTANCE.connection!.addComponent(data)
							.then(() => {
								if (this.componentList !== null) {
									this.componentList.setState({ blocked: true })
									this.componentList.fetch()
								}

								this.setState({
									fullscreenLoading: null
								})
							}) } /> })

				return this.clearModalId(modalId)
			}
		})
	}
}
