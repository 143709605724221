import * as React from "react"

import classnames from "classnames"
import omit from "../../lib/omit"

import * as style from "./input.less"

interface IExtendedInputProps {
	placeholder: string,
	width?: number
	children?: React.ReactNode,
	triggerEnterEvenWhenError?: boolean,
	error?: string | null,
	noCheckAtMount?: boolean,
	onEnter?(event: React.KeyboardEvent, input: HTMLInputElement, extendedInput: ExtendedInput): any,
	checker?(text: string): string | null
}

interface IExtendedInputState {
	error?: string | null,
	focus: boolean,
	label: boolean
}

export default class ExtendedInput extends
	React.Component<React.InputHTMLAttributes<HTMLInputElement> & IExtendedInputProps, IExtendedInputState> {

	private static id = 0

	private container: HTMLDivElement | null = null
	private input: HTMLInputElement | null = null

	private id: number = ExtendedInput.id++

	private hasTried: boolean = false

	constructor(props: Readonly<React.InputHTMLAttributes<HTMLInputElement> & IExtendedInputProps>) {
		super(props)

		this.state = {
			focus: props.autoFocus ? props.autoFocus : false,
			label: typeof props.value !== "undefined" || typeof props.defaultValue !== "undefined",
			error: props.error ? props.error : null
		}
	}

	public componentDidMount() {
		this.input!.addEventListener("keyup", this._handleEvent.bind(this))
		this.input!.addEventListener("focus", this._handleFocus.bind(this))
		this.input!.addEventListener("blur", this._handleBlur.bind(this))

		this._checkResult()
	}

	public render() {
		const inputId = "input-id-" + this.id

		if (this.props.id != null)
			this.container!.id = this.props.id

		let showError = !!this.state.error

		if (this.props.noCheckAtMount && showError && !this.hasTried)
			showError = false

		return <div className={ classnames(style.extendedInput, this.props.className!, {
			[style.error]: showError,
			[style.focus]: !!this.state.focus,
			[style.hasLabel]: !!this.state.label
		}) } ref={c => this.container = c}>
			<label htmlFor={ inputId }>{ this.props.placeholder }</label>
			<input {...this._propsExceptThings} id={ inputId } ref={c => this.input = c}  />
			{ this.children_ }
			{ this._renderError() }
		</div>
	}

	public get value() {
		return this.input!.value
	}

	public enter(evt: any = null): void {
		this._checkResult()

		if (!this.props.onEnter)
			return

		if (!this.props.triggerEnterEvenWhenError && this.state.error !== null)
			return

		this.props.onEnter(evt, this.input!, this)
	}

	public isValid() {
		return this.state.error === null
	}

	private get _propsExceptThings(): object {
		return omit(this.props, ["onEnter", "id", "className", "placeholder", "children", "checker", "noCheckAtMount"])
	}

	private get children_(): React.ReactNode {
		return React.Children.map(this.props.children, child =>
			React.cloneElement(child as React.ReactElement<any>,
				{ parentInput: this }))
	}

	private _handleFocus(e: FocusEvent) {
		if (!this.input)
			return

		this.setState({
			focus: true,
			label: true
		})
	}

	private _handleBlur(e: FocusEvent) {
		if (!this.input)
			return

		this.setState({
			focus: false,
			label: this.input!.value !== ""
		})
	}

	private _handleEvent(evt: KeyboardEvent) {
		this.hasTried = true
		this._checkResult()

		if (evt.keyCode === 13)
			this.enter(evt)
	}

	private _checkResult(): void {
		if (this.props.checker)
			this.setState({
				error: this.props.checker!(this.input!.value)
			})
		else
			this.setState({
				error: null
			})
	}

	private _renderError(): React.ReactNode {
		if (!this.hasTried && this.props.noCheckAtMount)
			return

		if (this.state.error)
			return <div className={style.errorContainer}>
				<p>{ this.state.error }</p>
			</div>
		return <></>
	}
}
