import * as React from "react"
import { NavLink } from "react-router-dom"

import classnames from "classnames"
import { CSSTransition } from "react-transition-group"
import { EIcons, Icon } from "../../res/icons"
import * as style from "./header.less"

/**
 * Représente un onglet dans le header
 */
interface INavigationTarget {
	/**
	 * Le pitit texte à côté
	 */
	name: string,
	/**
	 * Une icône
	 */
	icon: EIcons,
	/**
	 * Référence directe à <(Nav)Link> de ReactRouter
	 */
	exact?: boolean,
	/**
	 * Lien où pointe le tab
	 */
	to: string
}

interface IHeaderProps {
	/**
	 * La width du header, en px
	 */
	width?: number,
	/**
	 * Lorsque la width du document sera inférieure à celle-là,
	 * le header switchera en version mobile.
	 */
	mobileWidth?: number,
	/**
	 * Les tabs!
	 */
	tabs: INavigationTarget[]
}

interface IHeaderState {
	mobileMode: boolean,
	isOpened: boolean
}

export default class Header extends React.Component<IHeaderProps, IHeaderState> {
	protected width: number = 256
	protected mobileWidth: number = 968
	protected tabs: INavigationTarget[] = []

	private updateMobileMode = this._updateMobileMode.bind(this)

	constructor(props: IHeaderProps) {
		super(props)

		this.width = typeof props.width === "number" ? props.width : 256
		this.mobileWidth = typeof props.mobileWidth === "number" ? props.mobileWidth : 968

		this.tabs = props.tabs
	}

	public componentWillMount() {
		this._updateMobileMode()
	}

	public componentDidMount() {
		window.addEventListener("resize", this.updateMobileMode)
	}

	public componentWillUnmount() {
		window.removeEventListener("resize", this.updateMobileMode)
	}

	public render() {
		if (this.state.mobileMode) // version mobile
			return <CSSTransition in={this.state.isOpened} onEnter={(element, isAppearing) => {
				element.style.maxWidth = element.style.minWidth = this.width + "px"
			}} onExited={element => {
				element.style.maxWidth = element.style.minWidth = "0px"
			}} timeout={300} classNames={ {
				enterActive: style.headerAnimationActive,
				enterDone: style.headerAnimationActive
			} }>
				<div id="header-container" style={ {maxWidth: "0px"} }
					className={ classnames(style.mobile, style.headerAnimation) }>

					<div className={style.headerButtonContainer} onClick={ this._toggleOpenMobileHeader.bind(this) }>
						<div className={style.headerButton}></div>
					</div>

						<nav className={style.navHeader}>
							<div className={style.titleContainer}>
								<h2>maison.js</h2>
							</div>
							<ul onClick={ this._openMobileHeader.bind(this, false) }>
								{ this._renderTabs() }
							</ul>
						</nav>
						<div className={style.headerOverlay} style={ { left: this.width + "px" }}
							onClick={ this._openMobileHeader.bind(this, false) } />
					</div>
				</CSSTransition>

		// version non-mobile
		return <div id="header-container">
			<header style={ { width: this.width + "px"} }>
				<nav className={style.navHeader}>
					<div className={style.titleContainer}>
						<h2>maison.js</h2>
					</div>
					<ul>
						{ this._renderTabs() }
					</ul>
				</nav>

			</header>
			<div id="header-placeholder" style={ { width: this.width + "px"} }></div>
		</div>
	}

	private _updateMobileMode() {
		const isMobile = Math.max(document.documentElement!.clientWidth, window.innerWidth || 0) <= this.mobileWidth

		if (this.state !== null && isMobile === this.state.mobileMode)
			return

		this.setState({ mobileMode: isMobile, isOpened: false })
	}

	private _openMobileHeader(opened: boolean): void {
		this.setState({
			isOpened: opened
		})
	}

	private _toggleOpenMobileHeader(): void {
		this.setState({
			isOpened: !this.state.isOpened
		})
	}

	private _renderTabs(): React.ReactNode[] {
		const tabs = []

		for (const tabIndex of Object.keys(this.tabs)) {
			const tab = this.tabs[tabIndex]

			tabs.push(
			<li key={tabIndex}>
				<NavLink exact={ tab.exact ? true : false } to={ tab.to }>
					<Icon icon={ tab.icon }></Icon>
					<span>{ tab.name }</span>
				</NavLink>
			</li>)
		}

		return tabs
	}
}
