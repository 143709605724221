exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Palette generated by Material Palette - materialpalette.com/amber/deep-purple */\na {\n  color: gray;\n  text-decoration: none;\n  border-bottom: 1px dotted gray;\n}\n.arrowbutton__arrow-button-container___16h6m {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  -webkit-transform: translateX(125%);\n          transform: translateX(125%);\n  width: 28px;\n  height: 28px;\n  cursor: pointer;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n  border-radius: 50%;\n  overflow: hidden;\n  background: inherit;\n  border: 0;\n  outline: 0;\n  padding: 1px;\n  transition: padding 0.4s;\n}\n.arrowbutton__arrow-button-container___16h6m:after {\n  content: '';\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  display: block;\n  background: transparent;\n  top: 0px;\n  left: 0px;\n  transition: background 0.4s;\n}\n.arrowbutton__arrow-button-container___16h6m:hover {\n  padding: 3px;\n}\n.arrowbutton__arrow-button-container___16h6m:hover:after {\n  background: rgba(0, 0, 0, 0.15);\n}\n", ""]);

// exports
exports.locals = {
	"arrow-button-container": "arrowbutton__arrow-button-container___16h6m",
	"arrowButtonContainer": "arrowbutton__arrow-button-container___16h6m"
};