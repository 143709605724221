import * as React from "react"
import { NavLink } from "react-router-dom"

import ImageWithRoundedBackground from "../../components/design/img-with-rounded-bckg/ImageWithRoundedBackground"
import TopHomePageDesign from "../../components/design/top-page-design/TopPageDesign"
import WidthFixer from "../../components/design/width-fixer/WidthFixer"
import BasePageComponent from "../base-page-component/BasePageComponent"

import IfconfigExample from "../../../src/res/img/ifconfig-example.svg"
import TerminalSVG from "../../../src/res/img/terminal.svg"
import * as style from "./about-page.less"

export default class NotFoundPage extends React.Component {
	public render() {
		return <BasePageComponent className={ style.aboutPage }>
			<TopHomePageDesign topText="maison.js" bottomText="À propos"  height={150} />

		<WidthFixer>
			<h2 id={"projet"}>Le projet.</h2>

			<p>Ceci est le projet réunissant Nathan P., Mathis B., Dorian G.D.
				et Marwan A. pour les Travaux Personnels Encadrés, de 1ère S3.</p>

			<h2 id={"installation"}>Installation</h2>

			<p>Pour installer le projet, quelques prérequis sont nécessaires :</p>
			<ul>
				<li>Un Raspberry Pi (3 de préférence) avec son matériel (carte SD, câble d'alim.)</li>
				<li>De l'électronique avec les ports GPIOs</li>
				<li>Un ordinateur, et une connexion internet</li>
			</ul>

			<h3>Étape 1:</h3><p>Préparez le Raspberry Pi, et flashez-le avec une version de Linux.</p>

			<h3>Étape 2:</h3>
				<p>Une fois prêt, lancez un terminal sur le Raspi et :</p>

			<div>
				<ImageWithRoundedBackground className={ style.terminalImage } src={TerminalSVG} />

				<div className={ style.substeps }>
					<div>
						<p>Installez Node.JS en tapant ceci sur votre terminal, et en validant avec <i>Entrée</i></p>

						<code>
							curl -o- https://raw.githubusercontent.com/xtuple/nvm/master/install.sh | sudo bash
						</code>
						<p>Redémarrez votre terminal et tapez :</p>
						<code>
							nvm install node
						</code>
					</div>

					<div>
						<p>Installez maison.js</p>
						<code>{`cd ~/
							git clone https://gitlab.com/maison.js/server.git
							cd maison.js
							npm i
						`.replace(/\t/g, "")}</code>

						<p>Lancez maison.js avec :</p>
						<code>{`cd ~/maison.js
							sudo node .
						`.replace(/\t/g, "")}</code>
					</div>
				</div>
			</div>

			<h3>Étape 3:</h3>
				<p>Une fois le serveur lancé, il faut récupérer l'adresse IP du Raspberry : </p>

			<div className={ style.substeps }>
				<p>Plusieurs solutions sont possibles, si vous avec un accès rapide à votre routeur/box,&nbsp;
					cherchez l'adresse IP locale du Raspberry (sous forme: 192.168.X.X ou 10.0.X.X ou plus rarement 172.16.X.X)</p>

				<p>Dans le cas contraire, utilisez directement le Raspberry et entrez dans le terminal la commande&nbsp;
					<code>ip -4 -o addr show</code> ou <code>ifconfig</code> et cherchez l'adresse à côté de « inet ».</p>

				<p style={{ color: "gray", fontStyle: "italic" }}>Note: N'utilisez pas l'adresse 127.0.0.1</p>

				<h4>Exemple:</h4>

				<img src={IfconfigExample} />

				<p>Si vous voulez accéder à votre Raspberry à distance, il faudra ouvrir les ports sur votre routeur/box.
					Pour ceci, consultez le manuel de votre routeur.</p>

				<p>Vous pouvez enfin vous <NavLink to="/se-connecter">connecter</NavLink> au
					Raspberry avec l'adresse IP que vous avez trouvée.</p>
			</div>

		</WidthFixer>

		</BasePageComponent>
	}
}
