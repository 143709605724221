exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Palette generated by Material Palette - materialpalette.com/amber/deep-purple */\na {\n  color: gray;\n  text-decoration: none;\n  border-bottom: 1px dotted gray;\n}\n.base-button__base-button___BYjAl {\n  display: inline-block;\n  border-radius: 3px;\n}\n.base-button__base-button___BYjAl > button {\n  border: 0;\n  background: white;\n  padding: 8px 16px;\n  border-radius: inherit;\n  font-size: 0.85em;\n  transition: background 0.2s;\n  outline: none;\n  display: inline-block;\n  text-align: center;\n  position: relative;\n  cursor: pointer;\n}\n.base-button__base-button___BYjAl > button:before {\n  content: '';\n  width: 100%;\n  height: 100%;\n  display: block;\n  position: absolute;\n  background: black;\n  opacity: 0;\n  transition: opacity 0.4s;\n  top: 0px;\n  left: 0px;\n  border-radius: inherit;\n}\n.base-button__base-button___BYjAl > button:hover:before {\n  opacity: 0.2;\n}\n", ""]);

// exports
exports.locals = {
	"base-button": "base-button__base-button___BYjAl",
	"baseButton": "base-button__base-button___BYjAl"
};