import * as React from "react"
import { Redirect } from "react-router"

import ArrowButton from "../../components/buttons/arrow-button/ArrowButton"
import TopPageDesign from "../../components/design/top-page-design/TopPageDesign"
import Input from "../../components/input/Input"
import isValidIP from "../../lib/isValidIP"
import BasePageComponent from "../base-page-component/BasePageComponent"
import OldConnectionsFastConnect from "./OldConnectionsFastConnect"

import * as style from "./connect-page.less"

interface IConnectPageState {
	ip: string | null,
	triggeredFromInput: boolean | undefined
}

export default class ConnectPage extends React.Component<{}, IConnectPageState> {
	protected input: Input | null = null

	constructor(props: Readonly<{}>) {
		super(props)
		this.state = { ip: null, triggeredFromInput: undefined }
	}

	public render(): React.ReactNode {
		if (this.state.ip)
			if (this.state.triggeredFromInput || isValidIP(this.state.ip))
				return <Redirect to={ `/app/${ this.state.ip }/connect` } />

		return <BasePageComponent>
			<TopPageDesign height={192}
				topText="Connexion"
				bottomText="Saississez l'IP de votre contrôleur et cliquez sur la p'tite flèche pour continuer" />

			<Input type="ip" className={style.ipInput}
				autoComplete="off"
				placeholder="Adresse IP"
				ref={ component => this.input = component }
				onEnter={ (e, input) => this.setState({ triggeredFromInput: true, ip: input.value }) }
				checker={value => {
					if (value === "" || isValidIP(value))
						return null
					return "Entrez une adresse IP valide."
				}}>
				<ArrowButton />
			</Input>

			<OldConnectionsFastConnect />
		</BasePageComponent>
	}
}
