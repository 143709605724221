import * as React from "react"
import { Redirect } from "react-router"

import Connection from "../../app/Connection"
import AppInstance from "../../AppInstance"
import ArrowButton from "../../components/buttons/arrow-button/ArrowButton"
import BaseButton from "../../components/buttons/base-button/BaseButton"
import ColoredButton from "../../components/buttons/colored-button/ColoredButton"
import Input from "../../components/input/Input"
import Loading, { LoadingText } from "../../components/loading/Loading"
import CenterText from "../../components/util/center-text/CenterText"
import Errors from "../../enums/Errors"
import isValidIP from "../../lib/isValidIP"
import IP from "../../types/ip"
import BasePageComponent from "../base-page-component/BasePageComponent"

import ErrorConnectingSubpage from "./connecting-subcomponents/Error"
import PasswordConnectingSubpage from "./connecting-subcomponents/Password"

import * as styles from "./connecting-page.less"

const appInstance = AppInstance.init()

interface IConnectingPageProps {
	ip: string,
	path?: string
}

interface IConnectingPageState {
	aborted: boolean,
	connected: boolean,
	error: Errors | null,
	password: boolean | string,
	passwordEntered: boolean,
	lastPassFalse: boolean
}

export default class ConnectingPage extends React.Component<IConnectingPageProps, IConnectingPageState> {
	private path: string = "accueil"
	private unmount: boolean = false
	private connection: Connection | null = null

	constructor(props: Readonly<IConnectingPageProps>) {
		super(props)

		if (props.ip)
			this.state = {
				aborted: false,
				connected: false,
				error: isValidIP(props.ip) ? null : Errors.NOT_VALID_IP,
				password: false,
				passwordEntered: false,
				lastPassFalse: false
			}

		if (props.path)
			this.path = props.path
	}

	public render() {
		// Annulation
		if (this.state.aborted) {
			if (appInstance.connection)
				appInstance.connection.cancel()

			return <Redirect to="/se-connecter" />
		}

		// Si connecté
		if (this.state.connected)
			return <Redirect to={`/app/${this.props.ip}/${this.path}`} />

		// Password page
		if (this.state.password === true)
			return <BasePageComponent>
				<PasswordConnectingSubpage
					wasLastPassWrong={ this.state.lastPassFalse }
					onEnter={ value => this.setState({ password: value, passwordEntered: true }) } />
			</BasePageComponent>

		// Connexion
		if (typeof this.state.password === "string")
			this.connection!.password(this.state.password).then(r => {
				if (r === false)
					return this.setState({ lastPassFalse: true, password: true })
			})

		// Erreur
		if (this.state.error !== null)
			return <BasePageComponent>
				<ErrorConnectingSubpage
					error={ this.state.error }
					onBack={ () => { this.setState({ aborted: true })} }
					onRetry={ () => { this.setState({ error: null } )}} />

			</BasePageComponent>

		if (!this.state.passwordEntered)
			appInstance.connect( new IP(this.props.ip) ).on("connected", () => {
				// once connected, append ip to history and push it back to the end
				// by removing old key (and so, removing duplicates)
				let history = appInstance.lastConnections.get("history")

				if (!(history instanceof Array))
					history = []

				const index = history.indexOf(this.props.ip)

				if (index > -1)
					appInstance.lastConnections.remove("history", index)

				appInstance.lastConnections.add("history", this.props.ip)
				this.setState({ connected: true })
			})
				.on("awaiting-hello", connection => connection.hello())
				.on("password-required", connection => {
					this.connection = connection
					this.setState({ password: true })
				})
				.on("error", e => {
					if (this.unmount)
						return

					if (e in Errors)
						this.setState({ error: e })
					else
						this.setState({ error: Errors.NOT_WS })
				})
				.on("cancelled", e => {
					if (this.unmount) return

					this.setState({ error: Errors.UNEXPECTED_CLOSE })
				})

		// aucune chose spéciale à faire
		return <BasePageComponent>
			<CenterText>
				<Loading>
					<LoadingText>
						Connexion à {this.props.ip}
					</LoadingText>
					<BaseButton onClick={ () => {
						this.setState({ aborted: true })
					}}>Annuler</BaseButton>
				</Loading>
			</CenterText>
		</BasePageComponent>
	}

	public componentWillUnmount() {
		this.unmount = true
	}
}
