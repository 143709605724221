import { EventEmitter } from "events"
import { sha256 } from "js-sha256"
import Errors from "../enums/Errors"
import IP from "../types/ip"
import Component from "./Component"
import Crypto from "./Crypto"
import WebsocketMessage from "./WebsocketMessage"

enum ConnectionState {
	NULL,
	ERROR,
	CONNECTING,
	MAKINGSECURECONNECTION,
	AWAITINGHELLO,
	AWAITINGPASSWORD,
	CONNECTED,
	CANCELLED,
	DISCONNECTED
}

interface IConnection {
	/**
	 * Emit lorsqu'il y a une erreur :shrug:
	 */
	on(event: "error", listener: (error: Error, connection: Connection) => any): this,
	/**
	 * @event "cancelled" Emit lorsque la connexion est fermée et annulée pour de bon
	 * @event "awaiting-hello" Emit lorsqu'il est temps de dire bonjour et de se présenter
	 * @event "state-update" Emit lorsqu'il y a quelque chose de nouveau sur le status de la connexion
	 * @event "password-required" Emit lorsqu'il faut se connecter avec un mot de passe !
	 * @event "connected" Emit lorsque connecté
	 */
	on(event: "cancelled" | "awaiting-hello" | "state-update" | "setup-controller" | "password-required" | "connected",
	   listener: (connection: Connection) => any): this,
	/**
	 * Emit lorsque un message a été reçu, avec son id
	 * @private
	 */
	on(event: "message", listener: (id: number, message: MessageEvent, connection: Connection) => any): this
}

/**
 * Représente une connexion à un contrôleur
 */
class Connection extends EventEmitter implements IConnection {
	public connectionState: ConnectionState = ConnectionState.NULL

	private connection: WebSocket | null = null
	private cancelled: boolean = false
	private messageId: number = 0

	private crypto: Crypto = new Crypto()

	constructor(protected ip: IP) {
		super()

		this.on("error", () => {
			this.cancel()
		})
	}

	public connect() {
		this.connection = new WebSocket(`ws://${ this.ip }:80`)
		this.connectionState = ConnectionState.CONNECTING

		this.connection.addEventListener("open", e => {
			if (this.cancelled)
				return

			this.connectionState = ConnectionState.MAKINGSECURECONNECTION
			this.emit("state-update", this)

			this.createSecureConnection()
		})

		this.connection.addEventListener("error", e => {
			if (this.cancelled)
				return

			this.connectionState = ConnectionState.ERROR
			this.emit("error", e, this)
		})

		this.connection.addEventListener("close", e => {
			if (this.cancelled)
				return

			this.connectionState = ConnectionState.CANCELLED
			this.cancel()
		})

		this.connection.addEventListener("message", this.onmessage.bind(this, this.connection) as unknown as EventListener)
	}

	public cancel() {
		this.cancelled = true

		if (this.connection !== null)
			switch (this.connection.readyState) {
				case WebSocket.OPEN:
					this.connection.close()
					break
				case WebSocket.CONNECTING:
					this.connection.addEventListener("open", function() { this.close() })
					break
			}

		this.connection = null
		this.connectionState = ConnectionState.CANCELLED
		this.emit("cancelled", this)
	}

	public hello() {
		return new Promise((resolve, reject) => {
			if (this.connectionState !== ConnectionState.AWAITINGHELLO)
				return resolve()

			this.connectionState = ConnectionState.CONNECTING

			this.send(new WebsocketMessage("hello")).on("received", controllerData => {
				if (controllerData.hasOwnProperty("configured") &&
					controllerData.configured === false) {
					this.connectionState = ConnectionState.CONNECTED

					this.emit("setup-controller", this)
				}
				if (controllerData.hasOwnProperty("passwordRequired") && controllerData.passwordRequired === true) {
					this.connectionState = ConnectionState.AWAITINGPASSWORD
					this.emit("password-required", this)
				} else
					this._connected()

				resolve(controllerData)
			})
		})
	}

	public password(pass: string) {
		return new Promise((resolve, reject) => {
			if (this.connectionState !== ConnectionState.AWAITINGPASSWORD)
				return resolve()

			this.send(new WebsocketMessage("login", { password: sha256(pass) })).on("received", result => {
				if (result.result === true)
					this._connected()
				resolve(result.result)
			})
		})
	}

	public send(message: WebsocketMessage) {
		const ee = new EventEmitter(),
			  messageId = this.messageId++

		if (this.connection!.readyState === WebSocket.OPEN)
			this.connection!.send( this.crypto.crypt(JSON.stringify( message.serialize(messageId) )))
		else
			this.cancel()

		this.once(`message-${messageId}`, (id: number, incomingMessage: string) =>
			ee.emit("received", JSON.parse(incomingMessage))
		)

		return ee
	}

	public getComponents(): Promise<Component[]> {
		return new Promise((resolve, reject) => {
			if (this.connectionState !== ConnectionState.CONNECTED)
				return resolve([])

			this.send(new WebsocketMessage("get-components")).on("received", result => {
				resolve(Component.deserialize(result.components) as Component[])
			})
		})
	}

	public addComponent(component: Component): Promise<undefined> {
		return new Promise(resolve => {
			if (this.connectionState !== ConnectionState.CONNECTED)
				return resolve()

			this.send(new WebsocketMessage("add-component", {component})).on("received", result => {
				resolve()
			})
		})
	}

	public removeComponent(componentIndex: number): Promise<undefined> {
		return new Promise(resolve => {
			if (this.connectionState !== ConnectionState.CONNECTED)
				return resolve()

			this.send(new WebsocketMessage("remove-component", {componentIndex})).on("received", result => resolve())
		})
	}

	public setPinValue(pinId: number, value: number, componentIndex: number | null) {
		return new Promise((resolve, reject) => {
			if (this.connectionState !== ConnectionState.CONNECTED)
				return resolve()

			this.send(new WebsocketMessage("set-pin-value", { pinId, value, componentIndex })).on("received", result => {
				resolve(result.ok)
			})
		})
	}

	public disconnect() {
		return new Promise((resolve, reject) => {
			if (this.connectionState !== ConnectionState.CONNECTED)
				return resolve()

			this.send(new WebsocketMessage("disconnect"))
			this.connection!.close()
			return resolve()
		})
	}

	private createSecureConnection() {
		this.send(new WebsocketMessage("init-dh", { publicKey: this.crypto.getPublicKey() })).on("received", message => {
			this.crypto.computeSecret(message.publicKey)

			this.connectionState = ConnectionState.AWAITINGHELLO
			this.emit("awaiting-hello", this)
		})
	}

	private _connected() {
		this.connectionState = ConnectionState.CONNECTED
		this.emit("connected", this)
	}

	private onmessage(connection: WebSocket, ev: MessageEvent) {
		this.emit("message", ev)

		try {
			const data = this.crypto.decrypt(ev.data),
				obj = JSON.parse(data)

			if ( !(obj.hasOwnProperty("id") && obj.hasOwnProperty("type")) )
				return this.emit("error", Errors.NOT_VALID_SERVER)

			this.emit("message-" + obj.id, obj.id, data)
		} catch (e) {
			return this.emit("error", Errors.NOT_VALID_SERVER)
			console.error("Skipped error", e)
		}
	}
}

export default Connection
export { ConnectionState }
