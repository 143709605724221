import * as React from "react"
import { NavLink } from "react-router-dom"

import CenterText from "../../components/util/center-text/CenterText"

interface IPiHomePageProps {
	ip: string
}

export default class PiHomePage extends React.Component<IPiHomePageProps> {
	public render() {
		return <>
			<CenterText>
				<h4>Vous êtes maintenant connecté à votre Raspberry.</h4>
				<p>Vous pouvez maintenant aller configurer des appareils et les contrôler à distance en allant sur&nbsp;
					<NavLink to={"/app/" + this.props.ip + "/modules"}>Modules</NavLink></p>
			</CenterText>
		</>
	}
}
