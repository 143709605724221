import * as React from "react"

import classnames from "classnames"
import { CSSTransition } from "react-transition-group"

import * as style from "./modal.less"

interface IModalProps {
	onClose(modalId: number, data: any): void,
}

interface IModalBaseProps {
	dataKey: number
}

export { IModalBaseProps }

interface IModalState {
	mobile: boolean
}

export default class Modal<P = {}, S = {}> extends React.Component<P & IModalProps & IModalBaseProps, IModalState & S> {
	protected childrens: React.ReactNode

	private element: HTMLDivElement | null = null
	private resizeEvent = this.updateMobile.bind(this)
	private modalWidth: number | null = null

	private mounted: boolean = false

	constructor(props: Readonly<P & IModalProps & IModalBaseProps>) {
		super(props)

		this.state = { ...this.state, mobile: false }
	}

	public render() {
		return <div className={ style.modalBackground } >
			<CSSTransition in={this.mounted} timeout={200}
				classNames={{
					enterDone: style.modalAnimationEnterDone
				}}>
				<div className={ classnames({
					[style.mobile]: this.state.mobile }, style.modalContent) }
					ref={ c => this.element = c }>
					{ this.childrens }
				</div>
			</CSSTransition>
		</div>
	}

	public componentDidMount() {
		this.mounted = true
		this.updateMobile()
		window.addEventListener("resize", this.resizeEvent)
	}

	public componentWillUnmount() {
		window.removeEventListener("resize", this.resizeEvent)
	}

	protected onClose(data: any) {
		this.props.onClose(this.props.dataKey, data)
	}

	private updateMobile() {
		if (this.element === null)
			return console.log("is null")

		this.setState({ ...this.state,
			mobile: Math.max(document.documentElement!.clientWidth, window.innerWidth || 0) <= this.calculateModalWidth() })
	}

	private calculateModalWidth() {
		if (this.modalWidth === null && !this.state.mobile)
			this.modalWidth = this.element!.offsetWidth

		return this.modalWidth ? this.modalWidth : 500
	}
}
