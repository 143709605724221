exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".indentedtext__indented-text___3_MTY {\n  text-indent: 15px;\n  display: inline-block;\n}\n", ""]);

// exports
exports.locals = {
	"indented-text": "indentedtext__indented-text___3_MTY",
	"indentedText": "indentedtext__indented-text___3_MTY"
};