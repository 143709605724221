import * as React from "react"

import classnames from "classnames"
import multipleSameElements from "../../lib/multipleSameElements"
import { loadingAnimation, loadingContainer, loadingText } from "./loading.less"

export interface ILoadingProps {
	color?: string
	className?: string,
	children?: React.ReactNode
}

export default class Loading extends React.Component<ILoadingProps, {}> {
	public render() {
		const color = this.props.color ? this.props.color : "#000"

		return <div className={ classnames(this.props.className, loadingContainer) }>
				<div className={ loadingAnimation }>
					{ this._renderTheDivs(color) }
				</div>
				{ this.props.children! }
			</div>
	}

	private _renderTheDivs(backgroundColor: string) {
		return multipleSameElements(8, (i: number) => <div key={i} style={ { backgroundColor } }></div>)
	}
}

interface ILoadingTextProps {
	children: React.ReactNode
}

export class LoadingText extends React.Component<ILoadingTextProps, {}> {
	public render() {
		return <p className={ loadingText }>
			{ this.props.children }
			{ multipleSameElements(3, (i: number) => <span key={i}>.</span>) }
		</p>
	}
}
