import * as React from "react"

import omit from "../../../lib/omit"
import RippleApplier from "../appliers/ripple/RippleApplier"

import * as style from "./fab-button.less"

interface IFabButtonProps {
	children: React.ReactNode
}

type DivProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default class FabButton extends React.Component<IFabButtonProps & DivProps> {
	protected disabledProps = ["children"]

	constructor(props: Readonly<IFabButtonProps>) {
		super(props)
	}

	public render() {
		return <RippleApplier>
			<div className={ style.fabButton } {...this._props}>
				{ this.props.children }
			</div>
		</RippleApplier>
	}

	/**
	 * Evite de passer tous les props au <div>
	 */
	protected get _props(): DivProps {
		return omit(this.props, this.disabledProps)
	}
}
