import * as React from "react"

import ArrowButton from "../../../components/buttons/arrow-button/ArrowButton"
import Input from "../../../components/input/Input"
import CenterText from "../../../components/util/center-text/CenterText"
import { passwordPage as passwordPageStyle } from "../connecting-page.less"

interface IPasswordConnectingSubpageProps {
	wasLastPassWrong: boolean,
	onEnter(value: string): void
}

export default class PasswordConnectingSubpage extends React.Component<IPasswordConnectingSubpageProps> {
	public render() {
		return <div className={ passwordPageStyle }>
			<CenterText>
				<h3>La connexion nécessite un mot de passe :</h3>
				{ this.props.wasLastPassWrong ? <h4>Mot de passe erroné.</h4> : <></>}
			</CenterText>

			<Input type="password"
				autoComplete="off"
				placeholder="Mot de passe"
				onEnter={ (ev, input) => this.props.onEnter(input.value) } >
				<ArrowButton />
			</Input>
		</div>
	}
}
