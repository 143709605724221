import * as React from "react"
import SVG from "react-inlinesvg"

import AboutIcon from "./icons/about.svg"
import Add from "./icons/add.svg"
import RightArrow from "./icons/arrow-right.svg"
import Bulb from "./icons/bulb.svg"
import Connect from "./icons/connect.svg"
import Cross from "./icons/cross.svg"
import Devices from "./icons/devices.svg"
import Error from "./icons/error.svg"
import Help from "./icons/help.svg"
import HomeIcon from "./icons/home-icon.svg"
import Info from "./icons/info.svg"
import Settings from "./icons/settings.svg"

const Icons = { AboutIcon, HomeIcon, Devices, Connect, RightArrow, Add, Bulb, Error, Info, Cross, Settings, Help }

export { Icons as RawIcons }
export { EIcons }

enum EIcons {
	AboutIcon = "AboutIcon",
	HomeIcon = "HomeIcon",
	Devices = "Devices",
	Connect = "Connect",
	RightArrow = "RightArrow",
	Add = "Add",
	Bulb = "Bulb",
	Error = "Error",
	Info = "Info",
	Cross = "Cross",
	Settings = "Settings",
	Help = "Help"
}

interface IIconProps {
	icon: EIcons,
	alt?: string,
	className?: string
}

export class Icon extends React.Component<IIconProps, {}> {
	protected iconName: EIcons
	protected alt: string = "icon"

	constructor(props: IIconProps) {
		super(props)
		this.iconName = props.icon
		this.alt = props.alt!
	}

	public render() {
		// return <img className={this.props.className} src={Icons[this.iconName]} alt={this.alt} />
		return <SVG style={ { fill: "inherit" } } className={this.props.className} src={Icons[this.iconName]} />
	}
}
