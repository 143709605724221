import * as React from "react"

import classnames from "classnames"

import * as style from "./switch-checkbox.less"

interface ISwitchCheckboxProps {
	checked?: boolean,
	disabledLabelClick?: boolean,
	disabled?: boolean,
	onChange(checked: boolean): any
}

export default class SwitchCheckbox extends React.Component<ISwitchCheckboxProps> {
	protected static index: number = 0

	private checkbox: HTMLInputElement | null = null

	private id: string = "checkbox-" + SwitchCheckbox.index++

	constructor(props: Readonly<ISwitchCheckboxProps>) {
		super(props)
	}

	public render() {
		return <div className={ classnames(style.container, { [style.disabled]: this.props.disabled })}>
			<label htmlFor={this.props.disabledLabelClick ? "" : this.id}>{this.props.children}</label>

			<div className={style.designContainer}>
				<input id={this.id}
					defaultChecked={!!this.props.checked}
					disabled={this.props.disabled}
					type="checkbox"
					style={{display: "none"}}
					ref={c => this.checkbox = c}
					onChange={() => {
						this.triggerChangeEvent()
				}} />
				<label htmlFor={this.id} className={style.inputLabel}><span></span></label>
			</div>
		</div>
	}

	private triggerChangeEvent() {
		return this.props.onChange(this.checkbox!.checked)
	}
}
