import * as React from "react"
import { NavLink } from "react-router-dom"

import TopHomePageDesign from "../../components/design/top-page-design/TopPageDesign"
import WidthFixer from "../../components/design/width-fixer/WidthFixer"
import CenterText from "../../components/util/center-text/CenterText"
import BasePageComponent from "../base-page-component/BasePageComponent"
import DescriptionItem from "./sub-components/DescriptionItem"

import RaspberryImg from "../../res/img/raspberry.png"

export default class HomePage extends React.Component {
	public render() {
		return <BasePageComponent>
			<TopHomePageDesign topText="Maison.js" bottomText="Texte random pour le moment :')" />

			<br />

			<WidthFixer>
				<DescriptionItem imgSrc={RaspberryImg}>
					<h2>Utilisez un simple Raspberry pour contrôler votre maison</h2>

					<p>Suivez le tutoriel d'installation <NavLink to="/a-propos#installation">ici</NavLink>.</p>
				</DescriptionItem>

				<CenterText><h3>Raspberry déjà prêt ? <NavLink to="/se-connecter">Connectez-vous</NavLink></h3></CenterText>
			</WidthFixer>

		</BasePageComponent>
	}
}
