exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".connect-page__ip-input___2j5Sh {\n  position: relative;\n  left: 50%;\n  -webkit-transform: translateX(-50%);\n          transform: translateX(-50%);\n  width: 250px;\n}\n.connect-page__ip-input___2j5Sh input {\n  text-align: center;\n}\n", ""]);

// exports
exports.locals = {
	"ip-input": "connect-page__ip-input___2j5Sh",
	"ipInput": "connect-page__ip-input___2j5Sh"
};