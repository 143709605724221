import * as React from "react"
import BasePageComponent from "../base-page-component/BasePageComponent"

import AppInstance from "../../AppInstance"
import ColoredButton from "../../components/buttons/colored-button/ColoredButton"
import Input from "../../components/input/Input"

const APP_INSTANCE = AppInstance.init()

export default class DebugComponentsPage extends React.Component {
	private pinId: Input | null = null
	private value: Input | null = null

	public render() {
		return <BasePageComponent>
			<Input placeholder="pinId" ref={c => this.pinId = c} onEnter={() => {return}} />
			<Input placeholder="value (0-255)" ref={c => this.value = c}
				onEnter={() => {APP_INSTANCE.connection!
				.setPinValue(parseInt(this.pinId!.value, 10), parseInt(this.value!.value, 10), null)}} />
			<ColoredButton onClick={ () => {
				APP_INSTANCE.connection!.setPinValue(parseInt(this.pinId!.value, 10), parseInt(this.value!.value, 10), null) } }>
				Envoyer !</ColoredButton>
		</BasePageComponent>
	}
}
