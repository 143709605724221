import * as React from "react"

import Loading, { ILoadingProps } from "../../loading/Loading"

import * as style from "./fullscreen-loading.less"

interface IFullscreenLoadingProps {
	promiseInstance: Promise<any>
}

interface IFullscreenLoadingState {
	isResolved: boolean
}

export default class FullscreenLoading
	extends React.Component<IFullscreenLoadingProps & ILoadingProps, IFullscreenLoadingState> {
	private mount: boolean = false

	constructor(props: Readonly<IFullscreenLoadingProps & ILoadingProps>) {
		super(props)
		this.state = { isResolved: false }

		props.promiseInstance.then(domContent => {
			if (!this.mount)
				return
			this.setState({ isResolved: true })
		})
	}

	public render() {
		if (this.state.isResolved)
			return <></>

		return <div className={ style.fullscreenLoadingBackground }>
			<Loading color={ "gray" } { ...this._props } />
		</div>
	}

	public componentDidMount() {
		this.mount = true
	}

	public componentWillUnmount() {
		this.mount = false
	}

	private get _props(): ILoadingProps {
		return this.props as ILoadingProps
	}
}
