exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".components-page__title___3rLxV {\n  float: left;\n  display: inline-block;\n}\n.components-page__delete-button___3jyhB {\n  fill: #880c0c;\n  float: right;\n  height: 32px;\n  width: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 20px 10px;\n  position: relative;\n}\n", ""]);

// exports
exports.locals = {
	"title": "components-page__title___3rLxV",
	"delete-button": "components-page__delete-button___3jyhB",
	"deleteButton": "components-page__delete-button___3jyhB"
};